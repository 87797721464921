.Footer {
    font-family: Inter;
    position: relative;
    bottom: 0px;
    color : #FFFFFF;
    opacity: 0.7;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    a {
        font-family: Inter;
        color: #FFFFFF;
        text-decoration: underline;
        font-weight: normal;
    }
}