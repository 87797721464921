.SubmitContainer {
    margin-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SubmitTitle {
    color: #fbf88a;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0;
}

.SubmitDescription {
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    //font-family: 'Inter', sans-serif;
    letter-spacing: 0;
    line-height: 22px;
    white-space: pre-line;
}

.FormContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
}

.EmailInput {
    height: 36px;
    background-color: #FFFFFF;
    width: 273px;
    border-radius: 4px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.FormContainer {
    label {
        color: #565656;
        //font-family: Inter;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
}

.FormInputContainer {
    margin-top: 5px;

    input {
        font-size: 16px;
        border: 1px solid #565656;
        //font-family: 'Inter', sans-serif;
    }

    small {
        margin-top: 5px;
    }
}

.SweepstakesContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

input#sweepstakes-rules,
.SweepstakesCheck {
    height: 16px;
    width: 16px;
    margin: 4px;
    background-color: #fbf88a !important;
}

input[type="checkbox"]:checked {
    background-color: #fbf88a !important;
}

.SweepstakesCheck:checked {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    color: #333333;
    text-align: center;
    line-height: 15px;
    background-color: #fbf88a;

}

.EmailSubmitButton {
    height: 42px;
    width: 144px;
    border-radius: 9px;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
    border: none;
}

.SubmitSuccessTitle {
    margin-top: 30px;
    height: 27px;
    color: #FFFFFF;
    //font-family: Bungee;
    font-size: 27px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    z-index: 4;
}

.SubmitSuccessDescription {
    width: 326px;
    color: #FFFFFF;
    font-size: 15px;
    //font-family: 'Inter', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    z-index: 4;
}

.SubmitSuccessEmail {
    text-decoration: underline;
}

.AlreadyEnteredTitle {
    height: 48px;
    color: #FFFFFF;
    //font-family: Bungee;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
}

.AlreadyEnteredDescription {
    color: #FFFFFF;
    //font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}

.FoxButtonContainer {
    margin-top: 58px;
    text-align: center;
    width: 318px;
}

.FoxButton {
    height: 42px;
    width: 100%;
    border-radius: 9px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    border: none;
}

.FormSubmitDescription {
    height: auto;
    width: 270px;
    color: #4C4C4C;
    //font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 20px;
}

.CheckboxLabel {
    height: 60px;
    width: 245px;
    color: #565656;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    width: 245px;
    a {
        text-decoration: underline;
        color: #565656
    }
}

.ThankYou {
    a {
        color: #295FA1;
        font-size: 14px;
        text-decoration: none;
    }
}