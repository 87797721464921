.CountdownContainer {
    box-sizing: border-box;
    height: 68px;
    width: 68px;
    border: 1px solid rgba(255,255,255,0.25);
    background-color: rgba(255,255,255,0.2);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TimerValue {
    font-family: Bungee;
    font-size: 25px;
}