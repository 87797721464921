h4 {
    color: #4C4C4C;
    line-height: 0px;
    margin-top: 40px;
}

.tip {
    color: #03619E;
    font-weight: bold;
}

a {
    text-decoration: none;
    color: #007DC4;
    font-weight: bold;
}