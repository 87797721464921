.QuizCardContainer > * {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.QuizCardContainer {
    box-sizing: border-box;
    min-height: 367px;
    //padding-top: 5px;
    //padding-bottom: 5px;
    //width: 332px;
    //border: 1px solid rgba(255,255,255,0.25);
    border-radius: 10px;
    background-color: rgba(255,255,255,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        color: #4C4C4C;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 23px;
    }
}

.WinnerHeader {
    margin: 20px 0 14px 0;
    height: 40px;
}

.WinnerTitle {
    color: #FFFFFF;
    font-weight: 900;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;  
}

.WinnerSubTitle {
    color: #FBF88A;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;   
}

.SelectAnswer {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 11px;
}

.QuizQuestion{    
    font-size: 28px;
    letter-spacing: 0;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    text-shadow: 0.5px 0.5px 0 0 #EFAB16;
    text-transform: uppercase;
}
