// $bungee-font: 'Open Sans', 'Bungee', -apple-system, BlinkMacSystemFont, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
// 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

.CountdownContainer {
    margin: 90px 0 30px 0;
    box-sizing: border-box;
    height: 96px;
    width: 96px;
    border: 1px solid rgba(255,255,255,0.25);
    background-color: rgba(255,255,255,0.2);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.value {
    // font-family: $bungee-font;
    font-display: block;
    font-size: 20px;
}

.LoadingText {
    height: 32px;
    color: #FFFFFF;
    // font-family: $bungee-font;
    font-display: fallback;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
    max-width: 240px;   
    margin-top: 40px;
    font-weight: 600;
}

.LoadingSubText {
    height: 22px;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}