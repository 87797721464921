.LogoImageContainer {
    max-width: 250px;
    max-height: 144px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 35px;
}

.LogoImage {
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 375px) {  
    .LogoImageContainer {
        width: 320px;
        height: auto;
    }
}