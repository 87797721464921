.HeroContainer {
    max-height: 250px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // margin-top: 25px;
}

.LogoImageContainer {
    max-width: 100%;
    //max-height: 250px;
}

.LogoImage {
    height: 100%;
    width: 100%;
}

.CityScape {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

