.CommonCardPageContainer {
    width: 320px;
    position: relative;    
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Test {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 276px;
    //margin-bottom: 30px;
    bottom:60px;
    left: 50%;
    margin-left: -138px;
}

.CommonCardPageContent {
    height: 100%;
    padding: 30px 20px;

    h1:first-of-type {
        color: #06426A;
    }

    h1 {
        color: #4EA6FF;
    }

    hr {
        color: #CCCCCC;
    }

    p {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    span {
        color: #295FA1
    }
}

button {
    border: none;
    background: none;
}

.HowToPlay {
    margin-top: 40px;
    margin-bottom: 70px;
    a {    
        color: #FFFFFF;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        font-weight: bold;
        text-decoration: underline;
    }
}